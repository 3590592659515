import React, { useContext } from 'react'
import { Alert, Base, Grid, Text, margin, padding, position } from 'chassis-react'
import { Link } from '@reach/router'
import moment from 'moment'

import { UserContext } from '../../../context/UserContext'

import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import Hero from '../../../components/Hero'
import InfoBox from '../../../components/InfoBox'
import Layout from '../../../components/Layout'
import Panel from '../../../components/Panel'
import RoleCard from '../../../components/RoleCard'
import StepperList from '../../../components/StepperList'

const Dashboard = () => {
  const user = useContext(UserContext)
  const { Loaded } = user

  return Loaded ? (
    <Layout>
      <Hero welcome>{user.Firstname}</Hero>

      {user.Steps.every(step => step.Completed) && (
        <Alert wide success>
          <Grid container>
            <Grid row>
              <Grid colMd={8} util={position.centered}>
                <Text util={margin.b0}>
                  Congratulations, you have now completed all of the steps. We look forward to you joining us!
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Alert>
      )}

      <Base util={[padding.v3]}>
        <ContentWrap>
          <Text h2 util={margin.b4}>
            Your new role...
          </Text>

          <Grid row>
            <Grid colSm={4}>
              <RoleCard heading="Job title" details={user.JobTitleName} icon="person" />
            </Grid>

            <Grid colSm={4}>
              <RoleCard
                heading="Start date"
                details={moment.utc(user.StartDate).format('DD/MM/YYYY')}
                icon="calendar"
              />
            </Grid>

            <Grid colSm={4}>
              <RoleCard
                heading="Branch"
                details={user.BranchName}
                icon="location"
                link={`https://www.google.com/maps/search/${user.BranchName.split(' ').join('+')}`}
              />
            </Grid>
          </Grid>
        </ContentWrap>
      </Base>

      <Panel bgWhite bgGreySm util={[padding.v3, padding.v5Sm]}>
        <ContentWrap>
          <Text h4>What’s next?</Text>

          <Text>Keep things on track by completing the following steps…</Text>

          <StepperList steps={user.Steps} />
        </ContentWrap>
      </Panel>

      <Base util={[padding.v3]}>
        <ContentWrap>
          <Grid>
            <Grid row>
              <Grid colMd={12} util={[padding.v1]}>
                <InfoBox title="Got a question?" icon="question">
                  Starting a new job can be daunting. We are here to help every step of the way. Check out our{' '}
                  <Link to="/contact">Contact {'&'} FAQs section.</Link>
                </InfoBox>
              </Grid>
            </Grid>
          </Grid>
        </ContentWrap>
      </Base>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default Dashboard
