import React from 'react'
import PropTypes from 'prop-types'

import { Base, Text, margin, padding } from 'chassis-react'

import FormTextInput from '../../../../../components/FormTextInput'
import FormRadioGroup from '../../../../../components/FormRadioGroup'
import Panel from '../../../../../components/Panel'
import getErrorForField from '../../../../../utilities/getErrorForField'
import handleBlur from '../../../../../utilities/handleBlur'

const TrainingForm = ({ user, errors, setErrors, schema }) => {
  const { Apprentice } = user

  return (
    <Panel borderedSm bgWhiteSm util={[margin.b3, padding.a3Sm]}>
      <Text h3>GTG Training</Text>
      <Text>
        At the beginning of your apprenticeship, you will be required to attend the GTG Training Centre. If
        you are eligible for accommodation and travel expenses this will have been discussed with you at offer
        stage by a member of the Early Careers Team.
      </Text>
      <Text>If this has not been discussed with you please select No.</Text>


      <Base util={margin.b4}>
        <FormRadioGroup
          id="AccommodationRequired"
          label="Do you require accommodation during training?"
          name="AccommodationRequired"
          options={[
            { value: 'true', label: 'Yes', checked: Apprentice.AccommodationRequired },
            { value: 'false', label: 'No', checked: Apprentice.AccommodationRequired === false }
          ]}
          error={getErrorForField('AccommodationRequired', errors)}
          onChange={user.handleChangeApprentice}
          data-format="bool"
          onBlur={e => handleBlur(e, schema, Apprentice, setErrors)}
        />
      </Base>

    
      <Base util={margin.b4}>
        <FormRadioGroup
          id="TravelRequired"
          label="Do you require train travel?"
          name="TravelRequired"
          options={[
                    { value: 'true', label: 'Yes', checked: Apprentice.TravelRequired },
                    { value: 'false', label: 'No', checked: Apprentice.TravelRequired === false }
                  ]}
          error={getErrorForField('TravelRequired', errors)}
          onChange={user.handleChangeApprentice}
          data-format="bool"
          onBlur={e => handleBlur(e, schema, Apprentice, setErrors)}
        />
      </Base>
        
      {Apprentice.TravelRequired && (
      <Base util={margin.b4}>
        <FormTextInput
          id="TravelStation"
          label="Please state your nearest train station:"
          error={getErrorForField('TravelStation', errors)}
          name="TravelStation"
          onChange={user.handleChangeApprentice}
          onBlur={e => handleBlur(e, schema, Apprentice, setErrors)}
          value={Apprentice.TravelStation}
          autoComplete="off"
        />
      </Base>
              )}
    </Panel>
  )
}

TrainingForm.propTypes = {
  user: PropTypes.shape({
    Apprentice: PropTypes.shape({
      BootSize: PropTypes.string,
      OverallsSize: PropTypes.string,
      PoloShirtsSize: PropTypes.string
    })
  }),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      message: PropTypes.string
    })
  ),
  setErrors: PropTypes.func,
  schema: PropTypes.shape({})
}

export default TrainingForm
