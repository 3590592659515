import React, { useState, useContext } from 'react'
import { Button, Form, Grid, Text, margin, padding, position, shape, width } from 'chassis-react'
import moment from 'moment'

import { UserContext } from '../../../../context/UserContext'
import { PersonalDetailsSchema } from './schema'

import handleBlur from '../../../../utilities/handleBlur'
import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormSelectInput from '../../../../components/FormSelectInput'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

const DetailsEditPersonal = () => {
  const user = useContext(UserContext)
  const [ValidationErrors, setValidationErrors] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    PersonalDetailsSchema.validate(user, { abortEarly: false })
      .then(user.handleSubmitPersonalDetails)
      .catch(errors => {
        setValidationErrors(errors.inner)
        focusOnError()
      })
  }

  return user.Loaded ? (
    <Layout>
      <ContentWrap slim pad>
        <Text h2>Edit personal details</Text>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <Grid row>
              <Grid colSm={6}>
                <FormSelectInput
                  label="Title"
                  name="TitleId"
                  value={user.TitleId}
                  onChange={user.handleChangePersonalDetails}
                  data-format="int"
                  error={getErrorForField('TitleId', ValidationErrors)}
                  onBlur={e => handleBlur(e, PersonalDetailsSchema, user, setValidationErrors)}
                >
                  <option />

                  {user.Titles.map(title => (
                    <option value={title.Id} key={title.Id}>
                      {title.Name}
                    </option>
                  ))}
                </FormSelectInput>

                <FormTextInput
                  label="First name"
                  name="Firstname"
                  type="text"
                  value={user.Firstname}
                  onChange={user.handleChangePersonalDetails}
                  onBlur={e => handleBlur(e, PersonalDetailsSchema, user, setValidationErrors)}
                  error={getErrorForField('Firstname', ValidationErrors)}
                  autoComplete="off"
                />

                <FormTextInput
                  label="Surname"
                  name="Surname"
                  type="text"
                  value={user.Surname}
                  onChange={user.handleChangePersonalDetails}
                  onBlur={e => handleBlur(e, PersonalDetailsSchema, user, setValidationErrors)}
                  error={getErrorForField('Surname', ValidationErrors)}
                  autoComplete="off"
                />

                <FormTextInput
                  label="Date of birth"
                  name="DateOfBirth"
                  type="date"
                  value={user.DateOfBirth.slice(0, 10)}
                  onChange={user.handleChangePersonalDetails}
                  onBlur={e => handleBlur(e, PersonalDetailsSchema, user, setValidationErrors)}
                  error={getErrorForField('DateOfBirth', ValidationErrors)}
                  placeholder='yyyy-mm-dd'
                  pattern="\d{4}-\d{2}-\d{2}"
                  autoComplete="off"
                  max={moment.utc().format('YYYY-MM-DD')}
                />

                <FormTextInput
                  label="NI Number"
                  name="NINumber"
                  type="text"
                  value={user.NINumber}
                  onChange={user.handleChangePersonalDetails}
                  onBlur={e => handleBlur(e, PersonalDetailsSchema, user, setValidationErrors)}
                  error={getErrorForField('NINumber', ValidationErrors)}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Panel>

          <Button success block util={[width.resetSm, position.floatRight]}>
            Update
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DetailsEditPersonal
