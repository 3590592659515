import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'

import { Button, Form, Grid, Text, margin, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

import { BankDetailsSchema } from './schema'
import handleBlur from '../../../../utilities/handleBlur'
import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'

const WagesPaymentDetails = () => {
  const user = useContext(UserContext)
  const userTaskWageInfo = user.Steps.find(step => step.Step.Id === 5)
  const [ValidationErrors, setValidationErrors] = useState([])

  const { WageInformation } = user

  const handleSubmit = e => {
    e.preventDefault()

    BankDetailsSchema
      .validate(WageInformation, { abortEarly: false })
      .then(() => {
        user.handleSubmitWageInformation(e)
        navigate('/wages/confirm-details')
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  return user.Loaded ? (
    <Layout>
      {!userTaskWageInfo.Completed && <Stepper steps={stepperSteps.wages} currentStep={3} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm boxed>
            <Text util={margin.b2}>
              Please check all your information is correct to ensure you are paid on time.
            </Text>
            <Grid row>
              <Grid colSm={6}>
                <FormTextInput
                  label="Sort code"
                  name="SortCode"
                  util={width.w6}
                  value={user.WageInformation.SortCode || ''}
                  onChange={user.handleChangeWageInformation}
                  onBlur={e => handleBlur(e, BankDetailsSchema, WageInformation, setValidationErrors)}
                  error={getErrorForField('SortCode', ValidationErrors)}
                  autoComplete="off"
                />

                <FormTextInput
                  label="Account number"
                  name="AccountNumber"
                  value={user.WageInformation.AccountNumber || ''}
                  onChange={user.handleChangeWageInformation}
                  onBlur={e => handleBlur(e, BankDetailsSchema, WageInformation, setValidationErrors)}
                  error={getErrorForField('AccountNumber', ValidationErrors)}
                  autoComplete="off"
                />

                <FormTextInput
                  label="Account holder name"
                  name="AccountHolder"
                  value={user.WageInformation.AccountHolder || ''}
                  onChange={user.handleChangeWageInformation}
                  onBlur={e => handleBlur(e, BankDetailsSchema, WageInformation, setValidationErrors)}
                  error={getErrorForField('AccountHolder', ValidationErrors)}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Panel>

          <Button success util={[margin.t3, position.floatRight, width.w12, width.resetSm]}>
            {userTaskWageInfo.Completed ? 'Update' : 'Next: Confirm wages information'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default WagesPaymentDetails
