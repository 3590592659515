import React, { Component } from 'react'
import { Card, Text, Form, Button, margin } from 'chassis-react'
import { navigate } from '@reach/router'

import auth from '../../../auth'

import ContentWrap from '../../../components/ContentWrap'
import Hero from '../../../components/Hero'
import Layout from '../../../components/Layout'
import AuthGate from '../../../components/AuthGate'

class UpdatePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      confirmPassword: ''
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = e => {
    const { password, confirmPassword } = this.state

    e.preventDefault()

    if (password === confirmPassword) {
      auth.updatePassword(password).then(res => {
        if (res.data.Success === true) {
          navigate('/')
        }
      })
    }
  }

  render() {
    const { password, confirmPassword } = this.state

    return (
      <AuthGate userAccess>
      <Layout minimal>
        <Hero icon="person">
          Update your <br /> password
        </Hero>

        <ContentWrap pad tiny>
          <Form onSubmit={this.handleSubmit}>
            <Card>
              <Card content>
                <Text h4 util={margin.b0}>
                  Update password
                </Text>
                <br />
                <Text h5 util={margin.b0}>
                After resetting your password, you will be logged out. Please log back in using your new password.
                </Text>
              </Card>

              <Card content grey>
                <Form group>
                  <Form label>Password</Form>
                  <Form hint>Must be more than 8 characters long</Form>
                  <Form input type="password" name="password" onChange={this.handleChange} />
                </Form>

                <Form group>
                  <Form label>Confirm password</Form>
                  <Form input type="password" name="confirmPassword" onChange={this.handleChange} />
                </Form>
              </Card>

              <Card content>
                <Button success block disabled={password.length < 8 || password !== confirmPassword}>
                  Apply new password
                </Button>
                
              </Card>
            </Card>
          </Form>
        </ContentWrap>
      </Layout>
      </AuthGate>
    )
  }
}

UpdatePassword.propTypes = {}

export default UpdatePassword
