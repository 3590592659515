import React, { Fragment, useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { navigate } from '@reach/router'
import {
  Base,
  Card,
  Form,
  Grid,
  margin,
  Button,
  Text,
  padding,
  position,
  color,
  display
} from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import info from '../../../../static/images/icons/Info.svg'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormDivider from '../../../../components/FormDivider'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormSelectInput from '../../../../components/FormSelectInput'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import Modal from '../../../../components/Modal'

import { PersonalDetailsSchema, OtherNameSchema } from './schema'
import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'
import handleBlur from '../../../../utilities/handleBlur'
import { getNextRoute } from './routing'
import ValidationAlertList from '../../../../components/ValidationAlertList'

const DisclosurePersonalDetails = () => {
  const user = useContext(UserContext)
  const [ValidationErrors, setValidationErrors] = useState([])
  const [showModal, setShowModal] = useState(false)
  const { Steps, Loaded } = user
  const { Disclosure } = user

  const userTaskDisclosure = Steps.find(step => step.Step.Id === 4)
  const { KnownByOtherNames } = Disclosure

  const [otherNameObject, setOtherNameObject] = useState({
    TitleId: '',
    TitleName: '',
    Firstname: '',
    Middlename: '',
    Surname: '',
    UsedFrom: '',
    UsedTo: ''
  })
  const otherNameObjectInitialState = {
    DisclosureId: user.Disclosure.Id,
    TitleId: '',
    TitleName: '',
    Firstname: '',
    Middlename: '',
    Surname: '',
    UsedFrom: '',
    UsedTo: ''
  }

  useEffect(() => {
    user.handleLoadDisclosure()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    setValidationErrors([])

    PersonalDetailsSchema.validate(Disclosure, { abortEarly: false })
      .then(() => {
        user.handleSubmitDisclosure().then(res => {
          if (res === 200) {
            const newLocation = getNextRoute('personal-details', user.Id)
            navigate(newLocation)
          }
        })
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const handleOtherNameClick = e => {
    e.preventDefault()
    setValidationErrors([])

    OtherNameSchema.validate(otherNameObject, { abortEarly: false })
      .then(() => {
        user.handleAddDisclosureOtherNames({
          DisclosureId: user.Disclosure.Id,
          ...otherNameObject
        })
        setOtherNameObject(otherNameObjectInitialState)
      })
      .catch(error => {
        const filteredErrors = error.inner.map(err => {
          if (err.path === 'TitleId' || err.path === 'Firstname' || err.path === 'Surname') {
            return { ...err, path: `OtherName${err.path}` }
          }

          return err
        })
        setValidationErrors(filteredErrors)
        focusOnError()
      })
  }

  return Loaded ? (
    <Layout>
      {showModal && (
        <Modal util={[margin.b5]} onClick={() => setShowModal(!showModal)}>
          <Text util={[margin.t4, margin.t0Sm]}>
            We recognise that these gender options are limited. This is because this information will be sent
            directly to Disclosure Scotland/Disclosure Barring Service and we need to provide options that are
            compatible with their systems. If you’re based in Scotland and your gender identity or name has
            been different in the past, please speak with Disclosure Scotland{' '}
            <a target="blank" href="https://www.mygov.scot/transgender-disclosure-application">
              directly
            </a>
            . If you’re based in England, please contact the{' '}
            <a target="blank" href="https://www.gov.uk/request-copy-criminal-record">
              Disclosure Barring Service
            </a>
            .
          </Text>
        </Modal>
      )}
      <Stepper steps={stepperSteps.disclosure} currentStep={1} />

      {user.DisclosureApiValidationErrors && (
        <ValidationAlertList errors={user.DisclosureApiValidationErrors} />
      )}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel borderedSm boxed bgGreySm>
            <Grid row>
              <Grid colSm={6}>
                <div>
                  <Base util={[display.flex]}>
                    <FormRadioGroup
                      options={[
                        {
                          label: 'Male',
                          value: 1,
                          checked: user.Disclosure.GenderId === 1
                        },
                        {
                          label: 'Female',
                          value: 2,
                          checked: user.Disclosure.GenderId === 2
                        }
                      ]}
                      name="GenderId"
                      id="GenderId"
                      label="Gender"
                      data-format="int"
                      onChange={user.handleChangeDisclosure}
                      error={getErrorForField('GenderId', ValidationErrors)}
                      onBlur={e => handleBlur(e, PersonalDetailsSchema, Disclosure, setValidationErrors)}
                    />
                    <Base onClick={() => setShowModal(true)}>
                      <img style={{ height: '25px' }} src={info} alt="" />
                    </Base>
                  </Base>

                  <FormSelectInput
                    label="Title"
                    name="TitleId"
                    id="TitleId"
                    value={user.Disclosure.TitleId ? user.Disclosure.TitleId : 0}
                    onChange={user.handleChangeDisclosure}
                    data-format="int"
                    error={getErrorForField('TitleId', ValidationErrors)}
                    onBlur={e => handleBlur(e, PersonalDetailsSchema, user.Disclosure, setValidationErrors)}
                  >
                    <option value="">Please select...</option>

                    {user.Titles.map(title => (
                      <option key={title.Id} value={title.Id}>
                        {title.Name}
                      </option>
                    ))}
                  </FormSelectInput>

                  <FormTextInput
                    label="First name"
                    name="Forename"
                    value={user.Disclosure.Forename ? user.Disclosure.Forename : ''}
                    onChange={user.handleChangeDisclosure}
                    error={getErrorForField('Forename', ValidationErrors)}
                    onBlur={e => handleBlur(e, PersonalDetailsSchema, user.Disclosure, setValidationErrors)}
                    autoComplete="off"
                  />

                  <FormTextInput
                    label="Middle name(s) (optional)"
                    name="MiddleNames"
                    value={user.Disclosure.MiddleNames ? user.Disclosure.MiddleNames : ''}
                    onChange={user.handleChangeDisclosure}
                    autoComplete="off"
                  />

                  <FormTextInput
                    label="Surname"
                    name="Surname"
                    value={user.Disclosure.Surname ? user.Disclosure.Surname : ''}
                    onChange={user.handleChangeDisclosure}
                    error={getErrorForField('Surname', ValidationErrors)}
                    onBlur={e => handleBlur(e, PersonalDetailsSchema, user.Disclosure, setValidationErrors)}
                    autoComplete="off"
                  />
                </div>

                <FormDivider />

                <FormTextInput
                  label="Mothers maiden name"
                  name="MothersMaidenName"
                  value={user.Disclosure.MothersMaidenName ? user.Disclosure.MothersMaidenName : ''}
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('MothersMaidenName', ValidationErrors)}
                  onBlur={e => handleBlur(e, PersonalDetailsSchema, user.Disclosure, setValidationErrors)}
                  autoComplete="off"
                />
                <FormDivider />
              </Grid>
            </Grid>

            <Grid row>
              <Grid colSm={12}>
                <Text as="h5">Name Change Declaration</Text>

                <Text>
                  If you have changed your name -{' '}
                  <strong>for example through marriage, divorce, or any other reason</strong> - then please
                  supply these details.
                </Text>

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: 'true',
                      checked: KnownByOtherNames === true
                    },
                    {
                      label: 'No',
                      value: 'false',
                      checked: KnownByOtherNames === false
                    }
                  ]}
                  name="KnownByOtherNames"
                  label="Have you ever changed your name?"
                  error={getErrorForField('KnownByOtherNames', ValidationErrors)}
                  onChange={user.handleChangeKnownByOtherNames}
                  data-format="bool"
                />
              </Grid>
            </Grid>

            {!!KnownByOtherNames && (
              <Grid row>
                <Grid colSm={6}>
                  <Fragment>
                    <Base util={margin.t6}>
                      {!!getErrorForField('OtherNames', ValidationErrors) && (
                        <Form group error>
                          <Form validation>{getErrorForField('OtherNames', ValidationErrors)}</Form>
                        </Form>
                      )}

                      <FormSelectInput
                        label="Title"
                        name="OtherNameTitleId"
                        data-format="int"
                        value={otherNameObject.TitleId}
                        onChange={e => {
                          setOtherNameObject({
                            ...otherNameObject,
                            TitleId: +e.target.value,
                            TitleName: e.target.children[e.target.selectedIndex].textContent
                          })
                        }}
                        error={getErrorForField('OtherNameTitleId', ValidationErrors)}
                      >
                        <option value="">Please select...</option>

                        {user.Titles.map(title => (
                          <option key={title.Id} value={title.Id}>
                            {title.Name}
                          </option>
                        ))}
                      </FormSelectInput>

                      <FormTextInput
                        label="First name"
                        name="OtherNameFirstname"
                        value={otherNameObject.Firstname}
                        onChange={e =>
                          setOtherNameObject({
                            ...otherNameObject,
                            Firstname: e.target.value
                          })
                        }
                        error={getErrorForField('OtherNameFirstname', ValidationErrors)}
                      />
                      <FormTextInput
                        label="Middle name(s)"
                        name="OtherNameMiddlenames"
                        value={otherNameObject.Middlename}
                        onChange={e =>
                          setOtherNameObject({
                            ...otherNameObject,
                            Middlename: e.target.value
                          })
                        }
                      />
                      <FormTextInput
                        label="Surname"
                        name="OtherNameSurname"
                        value={otherNameObject.Surname}
                        onChange={e =>
                          setOtherNameObject({
                            ...otherNameObject,
                            Surname: e.target.value
                          })
                        }
                        error={getErrorForField('OtherNameSurname', ValidationErrors)}
                      />
                      <Grid row>
                        <Grid colXs={6}>
                          <FormTextInput
                            label="Used from"
                            name="OtherNameUsedFrom"
                            type="date"
                            value={otherNameObject.UsedFrom}
                            onChange={e =>
                              setOtherNameObject({
                                ...otherNameObject,
                                UsedFrom: e.target.value
                              })
                            }
                            max={moment.utc().format('YYYY-MM-DD')}
                            error={getErrorForField('UsedFrom', ValidationErrors)}
                            placeholder="yyyy-mm-dd"
                            pattern="\d{4}-\d{2}-\d{2}"
                          />
                        </Grid>

                        <Grid colXs={6}>
                          <FormTextInput
                            label="Used to"
                            name="OtherNameUsedTo"
                            type="date"
                            value={otherNameObject.UsedTo}
                            onChange={e =>
                              setOtherNameObject({
                                ...otherNameObject,
                                UsedTo: e.target.value
                              })
                            }
                            max={moment.utc().format('YYYY-MM-DD')}
                            error={getErrorForField('UsedTo', ValidationErrors)}
                            placeholder="yyyy-mm-dd"
                            pattern="\d{4}-\d{2}-\d{2}"
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Base>

                    <Button
                      type="button"
                      util={[margin.t3]}
                      onClick={() => {
                        setValidationErrors([])
                        setOtherNameObject(otherNameObjectInitialState)
                      }}
                    >
                      Clear
                    </Button>

                    <Button
                      secondary
                      type="button"
                      util={[position.floatRight, margin.t3]}
                      onClick={handleOtherNameClick}
                    >
                      Add other name
                    </Button>
                  </Fragment>
                </Grid>

                <Grid colSm={6} util={margin.t7}>
                  {user.Disclosure.OtherNames !== null &&
                    user.Disclosure.OtherNames.map((item, i) => (
                      <Card key={i} util={margin.t3}>
                        <Card content>
                          <dl>
                            {item.TitleName && (
                              <Fragment>
                                <dt>
                                  <small>Title</small>
                                </dt>
                                <dd>{item.TitleName}</dd>
                              </Fragment>
                            )}

                            {item.Firstname && (
                              <Fragment>
                                <dt>
                                  <small>Firstname</small>
                                </dt>
                                <dd>{item.Firstname}</dd>
                              </Fragment>
                            )}

                            {item.Middlename && (
                              <Fragment>
                                <dt>
                                  <small>Middlename</small>
                                </dt>
                                <dd>{item.Middlename}</dd>
                              </Fragment>
                            )}

                            {item.Surname && (
                              <Fragment>
                                <dt>
                                  <small>Surname</small>
                                </dt>
                                <dd>{item.Surname}</dd>
                              </Fragment>
                            )}

                            {item.UsedFrom && (
                              <Fragment>
                                <dt>
                                  <small>Used from</small>
                                </dt>
                                <dd>{moment.utc(item.UsedFrom).format('DD/MM/YYYY')}</dd>
                              </Fragment>
                            )}

                            {item.UsedTo && (
                              <Fragment>
                                <dt>
                                  <small>Used to</small>
                                </dt>
                                <dd>{moment.utc(item.UsedTo).format('DD/MM/YYYY')}</dd>
                              </Fragment>
                            )}
                          </dl>
                        </Card>

                        <Card content util={position.clearfix}>
                          <Button
                            link
                            type="button"
                            util={[color.acRed, position.floatRight, padding.a0]}
                            onClick={() => user.handleRemoveDisclosureOtherName(i)}
                          >
                            Remove
                          </Button>
                        </Card>
                      </Card>
                    ))}
                </Grid>
              </Grid>
            )}
          </Panel>

          <Button success util={[position.floatRight, margin.t3]}>
            {userTaskDisclosure && userTaskDisclosure.Completed ? 'Update' : 'Next: Birth details'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DisclosurePersonalDetails
