import { navigate } from '@reach/router'
import api from '../api'

const sessionKey = 'app-data'

const auth = {
  authenticateUser: data => {
    auth.updateSession({
      AuthToken: data.Token,
      Id: data.Id,
      IsAdmin: data.IsAdmin,
      AccessRole: data.AccessRole,
      AllRoles: data.AllRoles
    })
  },

  /**
   * @returns Object - A decrypted object from sessionStorage containing all information
   */
  getAppData: () => {
    const appDataStr = sessionStorage.getItem(sessionKey)
    return appDataStr ? auth.decrypt(appDataStr) : {}
  },

  /**
   * Takes the object passed in, and returns it stringified and base64 encoded
   *
   * @param  {Object} - The object to encrypt
   * @returns string
   */
  encrypt: obj => btoa(JSON.stringify(obj)),

  /**
   * Takes a string, which is run through base64 decode and parsed to an object.
   *
   * @param str - The string to decrypt
   * @returns Object - the parsed object
   * @returns undefined - if an empty string is passed in
   */
  decrypt: str => {
    if (str) {
      const json = atob(str)
      return JSON.parse(json)
    }
  },

  /**
   * Given a key, pull a value from sessionStorage
   *
   * @param string - the key to look for in the session object
   * @returns Object - the JS object returned from sessionStorage
   */
  getSessionValue: key => {
    const obj = auth.getAppData()
    return obj[key]
  },

  /**
   * Sets key / value pairs on the session
   *
   * @param object - values to set
   * @returns undefined
   */
  updateSession: newObj => {
    const obj = auth.getAppData()
    const newObject = { ...obj, ...newObj }
    const obfuscated = auth.encrypt(newObject)
    sessionStorage.setItem(sessionKey, obfuscated)
  },

  clearSession: () => {
    sessionStorage.clear()
  },

  logout: () => {
    sessionStorage.clear()
    api.authenticatedUser.signOut()
    .then(() => {
      window.location.href = '/'
    })
  },

  // Admin specific

  adminLogin: res => {
    const token = res.search.split('?data=')[1]
    api.employeeAuth
      .sendLoginData(token)
      .then(empRes => {
        auth.authenticateUser(empRes.data)
        return empRes
      })
      .then(empRes => {
        navigate(empRes.data.RedirectUrl)
      })
      .catch(err => err)
  },

  // User specific

  processMagicLink: async token => {
    if (token.includes('-')) {
      try {
        const res = await api.newStartMagicLinkAuth(token)
        auth.authenticateUser(res.data.LoginData)
        return res
      } catch (err) {
        return err
      }
    }

    try {
      const res1 = await api.passwordRequest.sendToken(token)
      auth.authenticateUser(res1.data)
      return res1
    } catch (err1) {
      return err1
    }
  },

  forgotPassword: email => api.passwordRequest.sendEmailAddress(email),

  updatePassword: password => api.newStartAuth.setPassword(password),

  userLogin: (email, password) =>
    api.newStartAuth.login(email, password).then(res => {
      auth.authenticateUser(res.data.LoginData)
      return res
    })
}

export default auth
