import React, { useContext, useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { Form, margin, Button, position, Grid, Base } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormDivider from '../../../../components/FormDivider'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormSelectInput from '../../../../components/FormSelectInput'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

import { generateAdditionalDetailsSchema } from './schema'
import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'
import handleBlur from '../../../../utilities/handleBlur'
import { getNextRoute } from './routing'
import ValidationAlertList from '../../../../components/ValidationAlertList'

const DisclosureAdditionalInformation = () => {
  const user = useContext(UserContext)
  const { Steps, Loaded } = user
  const userTaskDisclosure = Steps.find(step => step.Step.Id === 4)
  const [ValidationErrors, setValidationErrors] = useState([])
  const AdditionalDetailsSchema = generateAdditionalDetailsSchema(
    user.Disclosure.DateOfBirth,
    user.Disclosure.Surname,
    user.Disclosure.Forename,
    user.Disclosure.MiddleNames,
    user.Disclosure.GenderId
  )

  useEffect(() => {
    user.handleLoadDisclosure()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    setValidationErrors([])

    const { Disclosure } = user

    AdditionalDetailsSchema.validate(Disclosure, { abortEarly: false })
      .then(validationResult => {
        user.updateNiNumber(validationResult.UkNationalInsuranceNumber)
        user.updateDrivingLicence(validationResult.DrivingLicenceNumber)
        user.handleSubmitDisclosure().then(res => {
          if (res === 200){
            const newLocation = getNextRoute('additional-information', user.Id)
            navigate(newLocation)
          }
        })
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  return Loaded ? (
    <Layout>
      <Stepper steps={stepperSteps.disclosure} currentStep={3} />

      {user.DisclosureApiValidationErrors && (
        <ValidationAlertList errors={user.DisclosureApiValidationErrors} />
      )}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel borderedSm boxed bgGreySm>
            <Grid row>
              <Grid colSm={6}>
                <FormTextInput
                  label="UK National insurance number"
                  name="UkNationalInsuranceNumber"
                  value={
                    user.Disclosure.UkNationalInsuranceNumber ? user.Disclosure.UkNationalInsuranceNumber : ''
                  }
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('UkNationalInsuranceNumber', ValidationErrors)}
                  onBlur={e => handleBlur(e, AdditionalDetailsSchema, user.Disclosure, setValidationErrors)}
                  spellCheck="false"
                  autoComplete="off"
                />

                <FormDivider />

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: user.Disclosure.HasPassport
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: !user.Disclosure.HasPassport
                    }
                  ]}
                  label="Do you have a passport?"
                  id="HasPassport"
                  name="HasPassport"
                  data-format="bool"
                  onChange={user.handlePassport}
                />

                {user.Disclosure.HasPassport && (
                  <Base>
                    <FormTextInput
                      label="Passport number"
                      name="PassportNumber"
                      value={user.Disclosure.PassportNumber ? user.Disclosure.PassportNumber : ''}
                      onChange={user.handleChangeDisclosure}
                      error={getErrorForField('PassportNumber', ValidationErrors)}
                      onBlur={e =>
                        handleBlur(e, AdditionalDetailsSchema, user.Disclosure, setValidationErrors)
                      }
                      autoComplete="off"
                    />
                    <FormSelectInput
                      label="Passport country of issue"
                      name="PassportCountryOfIssueId"
                      value={
                        user.Disclosure.PassportCountryOfIssueId
                          ? user.Disclosure.PassportCountryOfIssueId
                          : ''
                      }
                      onChange={user.handleChangeDisclosure}
                      error={getErrorForField('PassportCountryOfIssueId', ValidationErrors)}
                      onBlur={e =>
                        handleBlur(e, AdditionalDetailsSchema, user.Disclosure, setValidationErrors)
                      }
                    >
                      <option value="">Please select...</option>

                      {user.CountryList.map(title => (
                        <option key={title.Id} value={title.Id}>
                          {title.CountryName}
                        </option>
                      ))}
                    </FormSelectInput>
                  </Base>
                )}

                <FormDivider />

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: user.Disclosure.HasDrivingLicence
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: !user.Disclosure.HasDrivingLicence
                    }
                  ]}
                  label="Do you have a driving licence?"
                  id="HasDrivingLicence"
                  name="HasDrivingLicence"
                  data-format="bool"
                  onChange={user.handleDrivingLicence}
                />

                {user.Disclosure.HasDrivingLicence && (
                  <Base>
                    <FormSelectInput
                      label="Driving licence country of issue"
                      name="DrivingLicenceCountryOfIssueId"
                      value={
                        user.Disclosure.DrivingLicenceCountryOfIssueId
                          ? user.Disclosure.DrivingLicenceCountryOfIssueId
                          : ''
                      }
                      onChange={user.handleChangeDisclosure}
                      error={getErrorForField('DrivingLicenceCountryOfIssueId', ValidationErrors)}
                    >
                      <option value="">Please select...</option>

                      {user.CountryList.map(title => (
                        <option key={title.Id} value={title.Id}>
                          {title.CountryName}
                        </option>
                      ))}
                    </FormSelectInput>

                    <FormTextInput
                      label="Driving Licence Number"
                      name="DrivingLicenceNumber"
                      value={user.Disclosure.DrivingLicenceNumber ? user.Disclosure.DrivingLicenceNumber : ''}
                      onChange={user.handleChangeDisclosure}
                      error={getErrorForField('DrivingLicenceNumber', ValidationErrors)}
                      onBlur={e =>
                        handleBlur(e, AdditionalDetailsSchema, user.Disclosure, setValidationErrors)
                      }
                      autoComplete="off"
                    />
                  </Base>
                )}

                <FormDivider />

                <FormRadioGroup
                  options={[
                    {
                      label: 'Yes',
                      value: true,
                      checked: user.Disclosure.HasNationalIdentityCard
                    },
                    {
                      label: 'No',
                      value: false,
                      checked: !user.Disclosure.HasNationalIdentityCard
                    }
                  ]}
                  label="Do you have a national identity card?"
                  id="HasNationalIdentityCard"
                  name="HasNationalIdentityCard"
                  data-format="bool"
                  onChange={user.handleNationalIdentity}
                />

                {user.Disclosure.HasNationalIdentityCard && (
                  <Base>
                    <FormSelectInput
                      label="National identity card country of issue"
                      name="NationalIdentityCardCountryOfIssueId"
                      value={user.Disclosure.NationalIdentityCardCountryOfIssueId || ''}
                      onChange={user.handleChangeDisclosure}
                      error={getErrorForField('NationalIdentityCardCountryOfIssueId', ValidationErrors)}
                      onBlur={e =>
                        handleBlur(e, AdditionalDetailsSchema, user.Disclosure, setValidationErrors)
                      }
                    >
                      <option value="">Please select...</option>

                      {user.CountryList.map(title => (
                        <option key={title.Id} value={title.Id}>
                          {title.CountryName}
                        </option>
                      ))}
                    </FormSelectInput>

                    <FormTextInput
                      label="National identity card number"
                      name="NationalIdentityCardNumber"
                      value={user.Disclosure.NationalIdentityCardNumber || ''}
                      onChange={user.handleChangeDisclosure}
                      onBlur={e =>
                        handleBlur(e, AdditionalDetailsSchema, user.Disclosure, setValidationErrors)
                      }
                      error={getErrorForField('NationalIdentityCardNumber', ValidationErrors)}
                      maxLength="20"
                      autoComplete="off"
                    />
                  </Base>
                )}
              </Grid>
            </Grid>
          </Panel>

          <Button success util={[position.floatRight, margin.t3]}>
            {userTaskDisclosure && userTaskDisclosure.Completed ? 'Update' : 'Next: Contact information'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DisclosureAdditionalInformation
