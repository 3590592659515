// Scottish postcode preifxes - minus Glasgow as it only has a single character prefix, this is dealt with separately
const scottishPostcodePrefixes = [
  'AB',
  'DD',
  'DG',
  'EH',
  'FK',
  'HS',
  'IV',
  'KA',
  'KW',
  'KY',
  'ML',
  'PA',
  'PH',
  'TD',
  'ZE'
]

/**
 * Takes a postcode and returns the first two character prefix
 *
 * @param {string} postCode - postcode to be parsed
 * @returns {string} string
 */
export const extractPostcodePrefix = postCode => {
  if (!postCode) return null

  let prefix = null

  prefix = postCode.slice(0, 2).toUpperCase()

  return prefix
}

/**
 * Validates wether or not the prefix belongs to a Glasgow postcode
 * Glasgow postcodes all start with a G and followed by a number
 * The number must always be 1 or greater
 * 
 * @param {string} prefix - postcode to be checked
 * @returns {boolean} boolean
 */
export const isGlasgowPostcodePrefix = prefix => {
  if (!prefix) return null

  let isGlasgow = false

  const isFirstCharacterG = prefix[0] === 'G'

  const isSecondCharacterInt = parseInt(prefix[1], 10) > 0

  isGlasgow = isFirstCharacterG && isSecondCharacterInt

  return isGlasgow
}

/**
 * Validates wether or not the prefix belongs to a Scottish postcode
 * First the above isGlasgow postcode method is called
 * If not the prefix is checked against the list above
 * 
 * @param {string} prefix - postcode to be checked
 * @returns {boolean} boolean
 */
export const isScottishPostcodePrefix = prefix => {
  if (!prefix) return null

  if (isGlasgowPostcodePrefix(prefix)) return true

  let isScottish = false
  isScottish = scottishPostcodePrefixes.includes(prefix)

  return isScottish
}

/**
 * Validates wether a postcode is valid against the disclosure service type
 * Scottish postcodes match with service type 2
 * All other valid UK postcodes match with service type 1
 * 
 * @param {string} postcode - disclosureServiceType to be compared
 * @param {string} disclosureServiceType - disclosureServiceType to be compared
 * @returns {boolean} boolean
 */
export const doesPostcodeMatchDisclosureServiceType = (postcode, disclosureServiceType) => {
  if (!postcode || !disclosureServiceType) return null
    const postcodePrefix = extractPostcodePrefix(postcode)
    const isScottishPostcode = isScottishPostcodePrefix(postcodePrefix)
  return (
    (isScottishPostcode && +disclosureServiceType === 2) ||
    (!isScottishPostcode && +disclosureServiceType === 1)
  )
}
