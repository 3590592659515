import React from 'react'
import { Text } from 'chassis-react'

import Layout from '../../../components/Layout'
import ContentWrap from '../../../components/ContentWrap'

const PrivacyPolicy = () => (
  <Layout>
    <ContentWrap pad>
      <Text h1>Privacy policy</Text>

      <Text>
        The following policy details the personal data we collect from you, the purpose it is collected for
        and which third parties your details may be passed to. This policy applies to all potential job
        candidates as well as our employees, temporary or agency workers and self-employed contractors both
        current and former.
      </Text>

      <Text>
        This policy amounts to a privacy notice for the purposes of the GDPR and contains all of the
        information which we are required to provide to you under data protection legislation
      </Text>

      <Text>
        <Text as="a" href="https://careers.arnoldclark.com/gdpr-candidate-agreement.aspx?fromRegistration=true">
        https://careers.arnoldclark.com/gdpr-candidate-agreement.aspx?fromRegistration=true
        </Text>
      </Text>

      <Text>
        <Text as="a" href="https://www.mygov.scot/disclosure-scotland-privacy/">
          https://www.mygov.scot/disclosure-scotland-privacy/
        </Text>
      </Text>

      <Text>
        <Text as="a" href="https://www.gov.uk/government/publications/dbs-privacy-policies">
          https://www.gov.uk/government/publications/dbs-privacy-policies
        </Text>
      </Text>
    </ContentWrap>
  </Layout>
)

export default PrivacyPolicy
