import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'
import { Button, Form, margin, position, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import ValidationAlertList from '../../../../components/ValidationAlertList'
import focusOnError from '../../../../utilities/focusOnError'

const WagesEmployeeStatement = () => {
  const user = useContext(UserContext)
  const userTaskWageInfo = user.Steps.find(step => step.Step.Id === 5)
  const [ValidationErrors, setValidationErrors] = useState([])

  const navigateToPage = () =>
    userTaskWageInfo.Completed ? navigate('/wages/confirm-details') : navigate('/wages/student-loan')

  const handleSubmit = e => {
    e.preventDefault()

    setValidationErrors([])

    if (!user.WageInformation.EmployeeStatementTypeId) {
      setValidationErrors([{ message: 'Please select a statement' }])
      focusOnError()
    } else {
      user.handleSubmitWageInformation(e)
      navigateToPage()
    }
  }

  return user.Loaded ? (
    <Layout>
      <ValidationAlertList errors={ValidationErrors} />
      {!userTaskWageInfo.Completed && <Stepper steps={stepperSteps.wages} currentStep={1} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm boxed>
            <FormRadioGroup
              large
              options={[
                {
                  label:
                    'This is my first job since last 6 April and I have not been receiving taxable Jobseeker’s Allowance, Employment and Support Allowance, taxable Incapacity Benefit, State or Occupational Pension.',
                  value: 1,
                  checked: user.WageInformation.EmployeeStatementTypeId === 1
                },
                {
                  label:
                    'This is now my only job since last 6 April, I have had another job, or recieved taxable Jobseeker’s Allowance, Employment and Support Allowance or taxable Incapacity Benefit. I do not receive a State or Occupational Pension.',
                  value: 2,
                  checked: user.WageInformation.EmployeeStatementTypeId === 2
                },
                {
                  label:
                    'As well as my new job, I have another job or receive a State or Occupational Pension.',
                  value: 3,
                  checked: user.WageInformation.EmployeeStatementTypeId === 3
                }
              ]}
              id="EmployeeStatementTypeId"
              name="EmployeeStatementTypeId"
              label="Please select one of the following statements:"
              data-format="int"
              onChange={user.handleChangeWageInformation}
            />
          </Panel>

          <Button success util={[margin.t3, position.floatRight, width.w12, width.resetSm]}>
            {userTaskWageInfo.Completed ? 'Update' : ' Next: Student loan information'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default WagesEmployeeStatement
