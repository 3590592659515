import React from 'react'
import { Link } from '@reach/router'
import { Base, Text, display, text, scale, padding } from 'chassis-react'
import moment from 'moment'

const copyrightYear = moment().year()

const Footer = () => (
  <Base
    as="footer"
    util={[scale.fs1, padding.v4, padding.h3, padding.h4Md, text.center]}
    className="app-footer"
  >
    <Text small util={[display.block, text.normal]}>
      {copyrightYear}{' '}
      <Base as="span" util={text.bold}>
        Arnold Clark Automobiles
      </Base>
      , All Rights Reserved. v{process.env.npm_package_version}
    </Text>

    <Link to="/privacy-policy">Privacy policy</Link>
  </Base>
)

export default Footer
