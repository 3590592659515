import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'
import { Button, Form, Grid, margin, padding, position, shape, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

import { AbsenceDetailsSchema } from './schema'
import focusOnError from '../../../../utilities/focusOnError'
import getErrorForField from '../../../../utilities/getErrorForField'

const MedicalAbsenceDetails = () => {
  const user = useContext(UserContext)
  const { handleChangeMedical, handleSubmitMedical, Medical, Loaded, Steps } = user
  const { NoDaysAbsentInLastTwelveMonths, HowManyPeriodsOfAbsence, DetailsOfEachAbsence } = Medical
  const userTaskMedicalForm = Steps.find(step => step.Step.Id === 7)
  const [ValidationErrors, setValidationErrors] = useState([])

  const handleSubmit = e => {
    e.preventDefault()

    AbsenceDetailsSchema.validate({NoDaysAbsentInLastTwelveMonths, HowManyPeriodsOfAbsence, DetailsOfEachAbsence}, { abortEarly: false })
      .then(() => {
        handleSubmitMedical()
        navigate(userTaskMedicalForm.Completed ? '/medical/confirm' : '/medical/history')
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  return Loaded ? (
    <Layout>
      {!userTaskMedicalForm.Completed && <Stepper steps={stepperSteps.medical} currentStep={1} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <Grid row>
              <Grid colSm={6}>
                <FormTextInput
                  label="Number of days absent in the last 12 months?"
                  name="NoDaysAbsentInLastTwelveMonths"
                  type="number"
                  value={NoDaysAbsentInLastTwelveMonths}
                  min="0"
                  max="365"
                  util={width.w3}
                  onChange={handleChangeMedical}
                  autoComplete="off"
                  error={getErrorForField('NoDaysAbsentInLastTwelveMonths', ValidationErrors)}
                />

                <FormTextInput
                  label="How many periods of absence?"
                  name="HowManyPeriodsOfAbsence"
                  type="number"
                  value={HowManyPeriodsOfAbsence}
                  min="0"
                  max="365"
                  util={width.w3}
                  onChange={handleChangeMedical}
                  autoComplete="off"
                  error={getErrorForField('HowManyPeriodsOfAbsence', ValidationErrors)}
                />

                <FormTextInput
                  label="Please give details of each absence:"
                  name="DetailsOfEachAbsence"
                  textarea
                  rows="5"
                  value={DetailsOfEachAbsence || ''}
                  onChange={handleChangeMedical}
                  autoComplete="off"
                  error={getErrorForField('DetailsOfEachAbsence', ValidationErrors)}
                />
              </Grid>
            </Grid>
          </Panel>

          <Button success util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}>
            {userTaskMedicalForm.Completed ? 'Update' : 'Next: Medical history'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

MedicalAbsenceDetails.propTypes = {}

export default MedicalAbsenceDetails
