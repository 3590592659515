import React, { Fragment, useContext, useState } from 'react'
import { Button, Form, Grid, margin, padding, position, shape, Text, width } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'

import { ContactSchema } from './schema'

import getErrorForField from '../../../../utilities/getErrorForField';
import focusOnError from '../../../../utilities/focusOnError'
import handleBlur from '../../../../utilities/handleBlur'

const emailAddressHint = (
  <Fragment>
    This is your unique username. Please <a href="/contact">contact us</a> to change it.
  </Fragment>
)

const DetailsEditContact = () => {
  const user = useContext(UserContext)
  const [validationErrors, setValidationErrors] = useState([])
  const { TelNumber, EmailAddress } = user
  const validateDetails = { TelNumber, EmailAddress }

  const handleSubmit = e => {
    e.preventDefault()
    setValidationErrors([])

    ContactSchema
      .validate(validateDetails, { abortEarly: false })
      .then(user.handleSubmitPersonalDetails)
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  return user.Loaded ? (
    <Layout>
      <ContentWrap slim pad>
        <Text h2>Edit contact details</Text>

        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <Grid row>
              <Grid colSm={6}>
                <FormTextInput
                  label="Telephone number"
                  name="TelNumber"
                  type="tel"
                  hint="Country code is mandatory, e.g. +44"
                  placeholder="+44 7890 *** ***"
                  value={user.TelNumber || ''}
                  onChange={user.handleChangePersonalDetails}
                  error={getErrorForField('TelNumber', validationErrors)}
                  onBlur={e => handleBlur(e, ContactSchema, validateDetails, setValidationErrors)}
                  autoComplete="off"
                />

                <FormTextInput
                  label="Email address"
                  name="EmailAddress"
                  hint={emailAddressHint}
                  type="email"
                  value={user.EmailAddress || ''}
                  onChange={user.handleChangePersonalDetails}
                  disabled
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Panel>

          <Button success block util={[width.resetSm, position.floatRight]}>
            Update
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}
export default DetailsEditContact
