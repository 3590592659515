import React, { useContext, useState } from 'react'
import { navigate } from '@reach/router'
import { Base, Button, Form, Text, margin, padding, position, shape, width } from 'chassis-react'
import moment from 'moment'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'
import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormRadioGroup from '../../../../components/FormRadioGroup'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'
import { MedicalSchema } from './schema'
import focusOnError from '../../../../utilities/focusOnError'
import getErrorForField from '../../../../utilities/getErrorForField'

const MedicalHistory = () => {
  const user = useContext(UserContext)
  const [ValidationErrors, setValidationErrors] = useState([])
  const { Loaded, handleChangeMedical, handleSubmitMedical, Steps, Medical } = user

  const userTaskMedicalForm = Steps.find(step => step.Step.Id === 7)

  const handleSubmit = e => {
    e.preventDefault()

    MedicalSchema.validate(Medical, { abortEarly: false })
      .then(() => {
        handleSubmitMedical()
        navigate('/medical/confirm')
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }
  const getAdditionalQuestions = (startDateName, endDateName, detailsName) => (
    <Base util={margin.b6}>
      <FormTextInput
        name={startDateName}
        type="date"
        label="Start date of condition"
        hint="Give a rough date if you are unsure"
        value={user.Medical[startDateName] ? user.Medical[startDateName].slice(0, 10) : ''}
        onChange={handleChangeMedical}
        util={width.w4Sm}
        placeholder="dd-mm-yyyy"
        error={getErrorForField(startDateName, ValidationErrors)}
        autoComplete="off"
        max={moment.utc().format('YYYY-MM-DD')}
      />
      <FormTextInput
        name={endDateName}
        type="date"
        label="End date of condition"
        hint="Leave blank if ongoing"
        value={user.Medical[endDateName] ? user.Medical[endDateName].slice(0, 10) : ''}
        onChange={handleChangeMedical}
        util={width.w4Sm}
        placeholder="dd-mm-yyyy"
        error={getErrorForField(endDateName, ValidationErrors)}
        autoComplete="off"
        max={moment.utc().format('YYYY-MM-DD')}
      />
      <FormTextInput
        label="Please provide details"
        name={detailsName}
        as="textarea"
        rows="2"
        util={width.w6Sm}
        onChange={handleChangeMedical}
        value={user.Medical[detailsName] || ''}
        error={getErrorForField(detailsName, ValidationErrors)}
        autoComplete="off"
      />
    </Base>
  )


  return Loaded ? (
    <Layout>
      {!userTaskMedicalForm.Completed && <Stepper steps={stepperSteps.medical} currentStep={2} />}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel bgGreySm borderedSm util={[padding.a3Sm, shape.rounded, margin.b3]}>
            <Text>Please indicate if any of the following apply or have applied to you in the past.</Text>

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.CirculatoryProblems
                },
                {
                  label: 'No',
                  value: false,
                  checked:
                    user.Medical.CirculatoryProblems === null ? false : !user.Medical.CirculatoryProblems
                }
              ]}
              name="CirculatoryProblems"
              onChange={handleChangeMedical}
              label="Circulatory problems?"
              hint="e.g. varicose veins, phlebitis or thrombosis"
              data-format="bool"
              error={getErrorForField('CirculatoryProblems', ValidationErrors)}
            />
            {user.Medical.CirculatoryProblems === true &&
              getAdditionalQuestions(
                'CirculatoryProblemsStartDate',
                'CirculatoryProblemsEndDate',
                'CirculatoryProblemsDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.HeartProblems
                },
                {
                  label: 'No',
                  value: false,
                  checked: user.Medical.HeartProblems === null ? false : !user.Medical.HeartProblems
                }
              ]}
              name="HeartProblems"
              onChange={handleChangeMedical}
              label="Heart problems?"
              hint="e.g. angina, high blood pressure or a heart attack"
              data-format="bool"
              error={getErrorForField('HeartProblems', ValidationErrors)}
            />

            {user.Medical.HeartProblems === true &&
              getAdditionalQuestions(
                'HeartProblemsStartDate',
                'HeartProblemsEndDate',
                'HeartProblemsDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.ChestProblems
                },
                {
                  label: 'No',
                  value: false,
                  checked: user.Medical.ChestProblems === null ? false : !user.Medical.ChestProblems
                }
              ]}
              name="ChestProblems"
              onChange={handleChangeMedical}
              label="Chest problems?"
              hint="e.g. asthma"
              data-format="bool"
              error={getErrorForField('ChestProblems', ValidationErrors)}
            />

            {user.Medical.ChestProblems === true &&
              getAdditionalQuestions(
                'ChestProblemsStartDate',
                'ChestProblemsEndDate',
                'ChestProblemsDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.EpilepsyOrFainting
                },
                {
                  label: 'No',
                  value: false,
                  checked: user.Medical.EpilepsyOrFainting === null ? false : !user.Medical.EpilepsyOrFainting
                }
              ]}
              name="EpilepsyOrFainting"
              onChange={handleChangeMedical}
              label="Epilepsy or fainting attacks?"
              data-format="bool"
              error={getErrorForField('EpilepsyOrFainting', ValidationErrors)}
            />

            {user.Medical.EpilepsyOrFainting === true &&
              getAdditionalQuestions(
                'EpilepsyOrFaintingStartDate',
                'EpilepsyOrFaintingEndDate',
                'EpilepsyOrFaintingDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.SkinDisorders
                },
                {
                  label: 'No',
                  value: false,
                  checked: user.Medical.SkinDisorders === null ? false : !user.Medical.SkinDisorders
                }
              ]}
              name="SkinDisorders"
              onChange={handleChangeMedical}
              label="Skin disorders?"
              data-format="bool"
              error={getErrorForField('SkinDisorders', ValidationErrors)}
            />

            {user.Medical.SkinDisorders === true &&
              getAdditionalQuestions(
                'SkinDisordersStartDate',
                'SkinDisordersEndDate',
                'SkinDisordersDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.OperationsOrFractures
                },
                {
                  label: 'No',
                  value: false,
                  checked:
                    user.Medical.OperationsOrFractures === null ? false : !user.Medical.OperationsOrFractures
                }
              ]}
              name="OperationsOrFractures"
              onChange={handleChangeMedical}
              label="Recent operations or fractures?"
              data-format="bool"
              error={getErrorForField('OperationsOrFractures', ValidationErrors)}
            />

            {user.Medical.OperationsOrFractures === true &&
              getAdditionalQuestions(
                'OperationsOrFracturesStartDate',
                'OperationsOrFracturesEndDate',
                'OperationsOrFracturesDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.AnyCurrentMedication
                },
                {
                  label: 'No',
                  value: false,
                  checked:
                    user.Medical.AnyCurrentMedication === null ? false : !user.Medical.AnyCurrentMedication
                }
              ]}
              name="AnyCurrentMedication"
              onChange={handleChangeMedical}
              label="Any current medication?"
              data-format="bool"
              error={getErrorForField('AnyCurrentMedication', ValidationErrors)}
            />

            {user.Medical.AnyCurrentMedication === true && (
              <Base util={margin.b6}>
                <FormTextInput
                  name="AnyCurrentMedicationStartDate"
                  type="date"
                  label="Start date of condition"
                  hint="Give a rough date if you are unsure"
                  value={
                    user.Medical.AnyCurrentMedicationStartDate
                      ? user.Medical.AnyCurrentMedicationStartDate.slice(0, 10)
                      : ''
                  }
                  onChange={handleChangeMedical}
                  util={width.w4Sm}
                  placeholder="dd-mm-yyyy"
                  error={getErrorForField('AnyCurrentMedicationStartDate', ValidationErrors)}
                  autoComplete="off"
                  max={moment.utc().format('YYYY-MM-DD')}
                />
                <FormTextInput
                  label="Please provide details"
                  name="AnyCurrentMedicationDetails"
                  as="textarea"
                  rows="2"
                  util={width.w6Sm}
                  onChange={handleChangeMedical}
                  value={user.Medical.AnyCurrentMedicationDetails || ''}
                  error={getErrorForField('AnyCurrentMedicationDetails', ValidationErrors)}
                  autoComplete="off"
                />
              </Base>
            )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.BackArthritisRheumatism
                },
                {
                  label: 'No',
                  value: false,
                  checked:
                    user.Medical.BackArthritisRheumatism === null
                      ? false
                      : !user.Medical.BackArthritisRheumatism
                }
              ]}
              name="BackArthritisRheumatism"
              onChange={handleChangeMedical}
              label="Back problems, arthritis and rheumatism?"
              data-format="bool"
              error={getErrorForField('BackArthritisRheumatism', ValidationErrors)}
            />

            {user.Medical.BackArthritisRheumatism === true &&
              getAdditionalQuestions(
                'BackArthritisRheumatismStartDate',
                'BackArthritisRheumatismEndDate',
                'BackArthritisRheumatismDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.BonesJointsTendons
                },
                {
                  label: 'No',
                  value: false,
                  checked: user.Medical.BonesJointsTendons === null ? false : !user.Medical.BonesJointsTendons
                }
              ]}
              name="BonesJointsTendons"
              onChange={handleChangeMedical}
              label="Injury to bones, joints or tendons?"
              hint="including wrist tendons"
              data-format="bool"
              error={getErrorForField('BonesJointsTendons', ValidationErrors)}
            />

            {user.Medical.BonesJointsTendons === true &&
              getAdditionalQuestions(
                'BonesJointsTendonsStartDate',
                'BonesJointsTendonsEndDate',
                'BonesJointsTendonsDetails'
              )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.OtherSignificantHealthProblems
                },
                {
                  label: 'No',
                  value: false,
                  checked:
                    user.Medical.OtherSignificantHealthProblems === null
                      ? false
                      : !user.Medical.OtherSignificantHealthProblems
                }
              ]}
              name="OtherSignificantHealthProblems"
              onChange={handleChangeMedical}
              label="Any other significant health problems?"
              data-format="bool"
              error={getErrorForField('OtherSignificantHealthProblems', ValidationErrors)}
            />

            {user.Medical.OtherSignificantHealthProblems === true && (
            <Base util={margin.b6}>
              <FormTextInput
                label="Please provide details"
                name="OtherSignificantHealthProblemsDetails"
                as="textarea"
                rows="2"
                util={width.w6Sm}
                onChange={handleChangeMedical}
                value={user.Medical.OtherSignificantHealthProblemsDetails || ''}
                error={getErrorForField('OtherSignificantHealthProblemsDetails', ValidationErrors)}
                autoComplete="off"
              />
            </Base>
            )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.WorkedWithHighNoiseLevels
                },
                {
                  label: 'No',
                  value: false,
                  checked:
                    user.Medical.WorkedWithHighNoiseLevels === null
                      ? false
                      : !user.Medical.WorkedWithHighNoiseLevels
                }
              ]}
              name="WorkedWithHighNoiseLevels"
              onChange={handleChangeMedical}
              label="Have you worked in an industry with high noise levels?"
              data-format="bool"
              error={getErrorForField('WorkedWithHighNoiseLevels', ValidationErrors)}
            />

            {user.Medical.WorkedWithHighNoiseLevels === true && (
            <Base util={margin.b6}>
              <FormTextInput
                label="Please provide details"
                name="WorkedWithHighNoiseLevelsDetails"
                as="textarea"
                rows="2"
                util={width.w6Sm}
                onChange={handleChangeMedical}
                value={user.Medical.WorkedWithHighNoiseLevelsDetails || ''}
                error={getErrorForField('WorkedWithHighNoiseLevelsDetails', ValidationErrors)}
                autoComplete="off"
              />
            </Base>
            )}

            <FormRadioGroup
              options={[
                {
                  label: 'Yes',
                  value: true,
                  checked: user.Medical.IndustrialInjuryClaim
                },
                {
                  label: 'No',
                  value: false,
                  checked:
                    user.Medical.IndustrialInjuryClaim === null ? false : !user.Medical.IndustrialInjuryClaim
                }
              ]}
              name="IndustrialInjuryClaim"
              onChange={handleChangeMedical}
              label="Have you ever made a claim for industrial injury?"
              data-format="bool"
              error={getErrorForField('IndustrialInjuryClaim', ValidationErrors)}
            />

            {user.Medical.IndustrialInjuryClaim === true && (
            <Base util={margin.b6}>
              <FormTextInput
                label="Please provide details"
                name="IndustrialInjuryClaimDetails"
                as="textarea"
                rows="2"
                util={width.w6Sm}
                onChange={handleChangeMedical}
                value={user.Medical.IndustrialInjuryClaimDetails || ''}
                error={getErrorForField('IndustrialInjuryClaimDetails', ValidationErrors)}
                autoComplete="off"
              />
            </Base>
            )}

            <Text h2 util={[margin.t6, margin.b2]}>
              Additional Information
            </Text>

            <FormTextInput
              label="Are there any other medical conditions that we may need to know about in order to support you within the workplace?"
              name="AnyOtherConditions"
              as="textarea"
              rows="5"
              util={width.w6Sm}
              onChange={handleChangeMedical}
              value={user.Medical.AnyOtherConditions || ''}
              autoComplete="off"
            />

            <Form label util={[margin.b2, margin.t4]}>
              Please confirm you are happy with us to share your additional information with relevant managers
              in order to best support you in the workplace.
            </Form>

            <Form
              checkbox
              onChange={handleChangeMedical}
              checked={!!user.Medical.ShareWithManagers}
              name="ShareWithManagers"
              // Sends the opposite value onChange
              value={!user.Medical.ShareWithManagers}
              data-format="bool"
            >
              Yes, I consent
            </Form>
          </Panel>

          <Button success util={[margin.b2, margin.b0Sm, width.w12, width.resetSm, position.floatRightSm]}>
            {userTaskMedicalForm.Completed ? 'Update' : 'Next: Confirm medical details'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default MedicalHistory
