import React, { useContext, useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import moment from 'moment'
import { Form, margin, Button, position, Grid } from 'chassis-react'

import { UserContext } from '../../../../context/UserContext'

import stepperSteps from '../../../../config/stepperSteps'

import ContentLoader from '../../../../components/ContentLoader'
import ContentWrap from '../../../../components/ContentWrap'
import FormSelectInput from '../../../../components/FormSelectInput'
import FormTextInput from '../../../../components/FormTextInput'
import Layout from '../../../../components/Layout'
import Panel from '../../../../components/Panel'
import Stepper from '../../../../components/Stepper'

import { BirthDetailsSchema } from './schema'
import getErrorForField from '../../../../utilities/getErrorForField'
import focusOnError from '../../../../utilities/focusOnError'
import handleBlur from '../../../../utilities/handleBlur'
import { getNextRoute } from './routing'
import ValidationAlertList from '../../../../components/ValidationAlertList'

const DisclosureBirthDetails = () => {
  const user = useContext(UserContext)
  const { Steps, Loaded } = user
  const userTaskDisclosure = Steps.find(step => step.Step.Id === 4)
  const [ValidationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    user.handleLoadDisclosure()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    const { Disclosure } = user
    const { DateOfBirth, TownOfBirth, CountryOfBirthId, NationalityId } = Disclosure

    BirthDetailsSchema.validate(
      { DateOfBirth, TownOfBirth, CountryOfBirthId, NationalityId },
      { abortEarly: false }
    )
      .then(() => {
        user.handleSubmitDisclosure().then(res => {
          if (res === 200) {
            const newLocation = getNextRoute('birth-details', user.Id)
            navigate(newLocation)
          }
        })
      })
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  return Loaded ? (
    <Layout>
      <Stepper steps={stepperSteps.disclosure} currentStep={2} />

      {user.DisclosureApiValidationErrors && (
        <ValidationAlertList errors={user.DisclosureApiValidationErrors} />
      )}

      <ContentWrap pad>
        <Form onSubmit={handleSubmit}>
          <Panel borderedSm boxed bgGreySm>
            <Grid row>
              <Grid colSm={6}>
                <FormTextInput
                  label="Date of birth"
                  name="DateOfBirth"
                  type="date"
                  value={user.Disclosure.DateOfBirth ? user.Disclosure.DateOfBirth.slice(0, 10) : ''}
                  onChange={user.handleChangeDisclosure}
                  min={moment.utc('1900-01-01').format('YYYY-MM-DD')}
                  max={moment.utc().format('YYYY-MM-DD')}
                  error={getErrorForField('DateOfBirth', ValidationErrors)}
                  onBlur={e => handleBlur(e, BirthDetailsSchema, user.Disclosure, setValidationErrors)}
                  placeholder="yyyy-mm-dd"
                  pattern="\d{4}-\d{2}-\d{2}"
                  autoComplete="off"
                />

                <FormTextInput
                  label="Town of birth"
                  name="TownOfBirth"
                  value={user.Disclosure.TownOfBirth ? user.Disclosure.TownOfBirth : ''}
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('TownOfBirth', ValidationErrors)}
                  onBlur={e => handleBlur(e, BirthDetailsSchema, user.Disclosure, setValidationErrors)}
                  autoComplete="off"
                />

                <FormSelectInput
                  label="Country of birth"
                  name="CountryOfBirthId"
                  data-format="int"
                  value={user.Disclosure.CountryOfBirthId ? user.Disclosure.CountryOfBirthId : ''}
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('CountryOfBirthId', ValidationErrors)}
                  onBlur={e => handleBlur(e, BirthDetailsSchema, user.Disclosure, setValidationErrors)}
                >
                  <option value="">Please select...</option>

                  {user.CountryList.map(item => (
                    <option key={item.Id} value={item.Id}>
                      {item.CountryName}
                    </option>
                  ))}
                </FormSelectInput>

                <FormSelectInput
                  label="Nationality"
                  name="NationalityId"
                  data-format="int"
                  value={user.Disclosure.NationalityId ? user.Disclosure.NationalityId : ''}
                  onChange={user.handleChangeDisclosure}
                  error={getErrorForField('NationalityId', ValidationErrors)}
                  onBlur={e => handleBlur(e, BirthDetailsSchema, user.Disclosure, setValidationErrors)}
                >
                  <option value="">Please select...</option>

                  {user.NationalityList.map(item => (
                    <option key={item.Id} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </FormSelectInput>
              </Grid>
            </Grid>
          </Panel>

          <Button success util={[position.floatRight, margin.t3]}>
            {userTaskDisclosure.Completed ? 'Update' : 'Next: Additional information'}
          </Button>
        </Form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default DisclosureBirthDetails
